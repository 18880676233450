import { Routes } from '@angular/router';
import { AuthGuard } from './core/services/authguard/auth.guard';

const currentLang = 'tr'
export const routes: Routes = [

  {
    path:':lang/my-global-mailbox',
    loadChildren: () => import('./modules/landing-pages/my-global-mailbox/my-global-mailbox.module').then(m => m.MyGlobalMailboxModule)
  },

  {
    path:':lang/abd-alisveris-gumruk',
    loadComponent: () => import('./modules/landing-pages/abd-shopping-customs/abd-shopping-customs.component').then(m => m.AbdShoppingCustomsComponent)
  },

  {
    path:':lang/faq',
    loadComponent: () => import('./modules/faq/faq.component').then(m => m.FaqComponent)
  },

  /*
  {
    path:'swiss-otel-bosphorus-halloween-party-2023',
    loadChildren: () => import('./modules/halloween/halloween.module').then(m => m.HalloweenModule)
  },
  {
    path:'amerikadaniste-golf-cup',
    loadChildren: () => import('./modules/golf-cup/golf-cup.module').then(m => m.GolfCupModule)
  },
  
  {
    path: 'columbia',
    redirectTo: currentLang + '/columbia',
  },
  {
    path: ':lang/columbia',
    loadChildren: () => import('./modules/colombia/colombia.module').then(m => m.ColombiaModule)
  },
  */
  {
    path: ':lang/newyear2025',
    loadComponent: () => import('./modules/landing-pages/holiday/holiday.component').then(m => m.HolidayComponent)
  },
  {
    path: ':lang/halloween',
    loadComponent: () => import('./modules/landing-pages/hallowen/hallowen.component').then(m => m.HallowenComponent)
  },
  {
    path: ':lang/kuveytturk',
    loadComponent: () => import('./modules/landing-pages/kuveyt-turk/kuveyt-turk/kuveyt-turk.component').then(m => m.KuveytTurkComponent)
  },
  {
    path: ':lang/maximiles-black',
    loadComponent: () => import('./modules/landing-pages/maximiles/maximiles-black.component').then(m => m.MaximilesBlackComponent)
  },
  {
    path: ':lang/garanti-bankasi',
    loadComponent: () => import('./modules/landing-pages/garanti-bank/garanti-bank.component').then(m => m.GarantiBankComponent)
  },
  {
    path: ':lang/amerikadaniste-advantage',
    loadComponent: () => import('./modules/ai-advantage/ai-advantage/ai-advantage.component').then(m => m.AiAdvantageComponent)
  },
  {
    path: ':lang/amerikadaniste-discounts',
    loadComponent: () => import('./modules/discount/discount/discount.component').then(m => m.DiscountComponent)
  },
  {
    path: ':lang/reliable-address-for-shopping-from-america',
    loadComponent: () => import('./modules/is-ai-reliable/is-ai-reliable/is-ai-reliable.component').then(m => m.IsAiReliableComponent)
  },
  {
    path: 'swiss-otel-bosphorus-halloween-party-2023',
    redirectTo: currentLang + '/swiss-otel-bosphorus-halloween-party-2023',
  },
  {
    path: ':lang/swiss-otel-bosphorus-halloween-party-2023',
    loadChildren: () => import('./modules/halloween/halloween.module').then(m => m.HalloweenModule)
  },
  {
    path: 'new-year-2024',
    redirectTo: currentLang + '/new-year-2024',
  },
  {
    path: ':lang/new-year-2024',
    loadChildren: () => import('./modules/new-year/new-year.module').then(m => m.NewYearModule)
  },
  {
    path: 'swiss-otel-halloween-party-contact',
    redirectTo: currentLang + '/swiss-otel-halloween-party-contact',
  },
  {
    path: ':lang/swiss-otel-halloween-party-contact',
    loadChildren: () => import('./modules/halloween-contact/halloween-contact.module').then(m => m.HalloweenContactModule)
  },
  {
    path: 'amerikadaniste-golf-cup',
    redirectTo: currentLang + '/amerikadaniste-golf-cup',
  },
  {
    path: ':lang/amerikadaniste-golf-cup',
    loadChildren: () => import('./modules/golf-cup/golf-cup.module').then(m => m.GolfCupModule)
  },
  {
    path: 'amerikadaniste-golf-cup-contact',
    redirectTo: currentLang + '/amerikadaniste-golf-cup-contact',
  },
  {
    path: ':lang/amerikadaniste-golf-cup-contact',
    loadChildren: () => import('./modules/golf-cup-contact/golf-cup-contact.module').then(m => m.GolfCupContactModule)
  },
  {
    path: 'shopping',
    redirectTo: currentLang + '/shopping',
  },
  {
    path: ':lang/shopping',
    loadChildren: () => import('./modules/shopping/shopping.module').then(m => m.ShoppingModule)
  },
  {
    path: ':lang/prices',
    loadChildren: () => import('./modules/prices/prices.module').then(m => m.PricesModule),
  },
  {
    path: 'prices',
    redirectTo: currentLang + '/prices',
  },
  {
    path: ':lang/signin',
    loadChildren: () => import('./modules/signin/signin.module').then(m => m.SigninModule),
  },
  {
    path: 'signin',
    redirectTo: currentLang + '/signin',
  },
  {
    path: ':lang/signin-options',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/signin-options/signin-options.module').then(m => m.SigninOptionsModule),
  },
  {
    path: 'signin-options',
    redirectTo: currentLang + '/signin-options',
  },
  {
    path: 'how-it-works',
    redirectTo: currentLang + '/how-it-works',
  },
  {
    path: ':lang/how-it-works',
    loadChildren: () => import('./modules/how-it-works/how-it-works.module').then(m => m.HowItWorksModule),
  },
  {
    path: ':lang/how-it-works-global',
    loadChildren: () => import('./modules/how-it-works-uk/how-it-works-uk.module').then(m => m.HowItWorksUkModule),
  },
  {
    path: 'how-it-works-global',
    redirectTo: currentLang + '/how-it-works-uk',
  },
  {
    path: ':lang/survey/:id',
    loadChildren: () => import('./modules/survey/survey.module').then(m => m.SurveyModule),
  },
  {
    path: 'survey/:id',
    redirectTo: currentLang + '/survey/:id',
  },
  {
    path: ':lang/buy-for-me',
    loadChildren: () => import('./modules/buy-for-me/buy-for-me.module').then(m => m.BuyForMeModule),
  },
  {
    path: 'buy-for-me',
    redirectTo: currentLang + '/buy-for-me',
  },
  {
    path: ':lang/about-us',
    loadChildren: () => import('./modules/about-us/about-us.module').then(m => m.AboutUsModule),
  },
  {
    path: 'about-us',
    redirectTo: currentLang + '/about-us',
  },
  {
    path: ':lang/contact',
    loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule),
  },
  {
    path: 'contact',
    redirectTo: currentLang + '/contact',
  },
  {
    path: ':lang/collections/:path',
    loadChildren: () => import('./modules/collection/collection.module').then(m => m.CollectionModule),
  },
  {
    path: 'collections/:path',
    redirectTo: currentLang + '/collections/:path',
  },
  {
    path: ':lang/campaigndetails/:path',
    loadChildren: () => import('./modules/campaign-details/campaign-details.module').then(m => m.CampaignDetailsModule),
  },
  {
    path: 'campaigndetails/:path',
    redirectTo: currentLang + '/campaigndetails/:path',
  },
  {
    path: ':lang/cookies-policy',
    loadChildren: () => import('./modules/cookies-policy/cookies-policy.module').then(m => m.CookiesPolicyModule),
  },
  {
    path: 'cookies-policy',
    redirectTo: currentLang + '/cookies-policy',
  },
  {
    path: ':lang/forgot-password',
    loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'forgot-password',
    redirectTo: currentLang + '/forgot-password',
  },
  {
    path: ':lang/landing-pages',
    loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule),
  },
  {
    path: 'landing-pages',
    redirectTo: currentLang + '/landing-pages',
  },
  {
    path: ':lang/privacy-policy',
    loadChildren: () => import('./modules/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
  },
  {
    path: 'privacy-policy',
    redirectTo: currentLang + '/privacy-policy',
  },
  {
    path: ':lang/reset-password',
    loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'reset-password',
    redirectTo: currentLang + '/reset-password',
  },
  {
    path: ':lang/signup',
    loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignupModule),
  },
  {
    path: 'signup',
    redirectTo: currentLang + '/signup',
  },
  {
    path: ':lang/signup-and-bfm',
    loadChildren: () => import('./modules/signup-and-bfm/signup-and-bfm.module').then(m => m.SignupAndBfmModule),
  },
  {
    path: 'signup-and-bfm',
    redirectTo: currentLang + '/signup-and-bfm',
  },
  {
    path: ':lang/user-agreement-page',
    loadChildren: () => import('./modules/user-agreement-page/user-agreement-page.module').then(m => m.UserAgreementPageModule),
  },
  {
    path: 'user-agreement-page',
    redirectTo: currentLang + '/user-agreement-page',
  },
  {
    path: ':lang/welcome',
    loadChildren: () => import('./modules/welcome/welcome.module').then(m => m.WelcomeModule),
  },
  {
    path: 'welcome',
    redirectTo: currentLang + '/welcome',
  },
  {
    path: ':lang/user',
    loadChildren: () => import('./modules/user-component/user-component.module').then(m => m.UserComponentModule),
  },
  {
    path: 'user',
    redirectTo: currentLang + '/user',
  },
  {
    path: ':lang/privacy-policy',
    loadChildren: () => import('./modules/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
  },
  {
    path: 'privacy-policy',
    redirectTo: currentLang + '/privacy-policy',
  },
  {
    path: ':lang/cookies-policy',
    loadChildren: () => import('./modules/cookies-policy/cookies-policy.module').then(m => m.CookiesPolicyModule),
  },
  {
    path: 'cookies-policy',
    redirectTo: currentLang + '/cookies-policy',
  },
  {
    path: ':lang/view-as-user',
    loadChildren: () => import('./modules/view-as-user/view-as-user.module').then(m => m.ViewAsUserModule),
  },
  {
    path: 'view-as-user',
    redirectTo: currentLang + '/view-as-user',
  },
  {
    path: ':lang/blackfriday',
    loadChildren: () => import('./modules/black-friday/black-friday.module').then(m => m.BlackFridayModule),
  },
  {
    path: 'blackfriday',
    redirectTo: currentLang + '/blackfriday',
  },
  // landing pages
  { path: ':lang/ebay-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'ebay-alisveris',
    redirectTo: currentLang + '/ebay-alisveris',
  },
  { path: ':lang/shein-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'shein-alisveris',
    redirectTo: currentLang + '/shein-alisveris',
  },
  { path: ':lang/anthropologie-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'anthropologie-alisveris',
    redirectTo: currentLang + '/anthropologie-alisveris',
  },
  { path: ':lang/alexander-mcqueen-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'alexander-mcqueen-alisveris',
    redirectTo: currentLang + '/alexander-mcqueen-alisveris',
  },
  { path: ':lang/amazon-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'amazon-alisveris',
    redirectTo: currentLang + '/amazon-alisveris',
  },
  { path: ':lang/champion-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'champion-alisveris',
    redirectTo: currentLang + '/champion-alisveris',
  },
  { path: ':lang/bestBuy-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'bestBuy-alisveris',
    redirectTo: currentLang + '/bestBuy-alisveris',
  },
  { path: ':lang/farfetch-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'farfetch-alisveris',
    redirectTo: currentLang + '/farfetch-alisveris',
  },
  { path: ':lang/neiman-marcus-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'neiman-marcus-alisveris',
    redirectTo: currentLang + '/neiman-marcus-alisveris',
  },
  { path: ':lang/victoria-secret-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'victoria-secret-alisveris',
    redirectTo: currentLang + '/victoria-secret-alisveris',
  },
  { path: ':lang/topshop-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'topshop-alisveris',
    redirectTo: currentLang + '/topshop-alisveris',
  },
  { path: ':lang/forever-21-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'forever-21-alisveris',
    redirectTo: currentLang + '/forever-21-alisveris',
  },
  { path: ':lang/urban-outfitters-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'urban-outfitters-alisveris',
    redirectTo: currentLang + '/urban-outfitters-alisveris',
  },
  { path: ':lang/gap-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'gap-alisveris',
    redirectTo: currentLang + '/gap-alisveris',
  },
  { path: ':lang/gucci-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'gucci-alisveris',
    redirectTo: currentLang + '/gucci-alisveris',
  },
  { path: ':lang/burberry-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'burberry-alisveris',
    redirectTo: currentLang + '/burberry-alisveris',
  },
  { path: ':lang/off-white-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'off-white-alisveris',
    redirectTo: currentLang + '/off-white-alisveris',
  },
  { path: ':lang/ugg-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'ugg-alisveris',
    redirectTo: currentLang + '/ugg-alisveris',
  },
  { path: ':lang/new-balance-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'new-balance-alisveris',
    redirectTo: currentLang + '/new-balance-alisveris',
  },
  { path: ':lang/golden-goose-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'golden-goose-alisveris',
    redirectTo: currentLang + '/golden-goose-alisveris',
  },
  { path: ':lang/zara-alisveris', loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: 'zara-alisveris',
    redirectTo: currentLang + '/zara-alisveris',
  },
  {
    path: 'az', loadChildren: () => import('./modules/az-lp/az-lp01.module').then(m => m.AzLPModule)
  },

  {
    path: 'tr',
    loadComponent: () => import('./modules/main/components/main/main.component').then(m => m.MainComponent)
  },
  {
    path: 'en',
    loadComponent: () => import('./modules/main/components/main/main.component').then(m => m.MainComponent)
  },
  {
    path: ':lang/:brand',
    loadChildren: () => import('./modules/shopping/shopping.module').then(m => m.ShoppingModule)
  },
  {
    path: ':brand',
    redirectTo: currentLang + '/:brand',
  },
  {
    path: '',
    redirectTo: currentLang,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: currentLang,
    pathMatch: 'full'
  }
];
